<script>
import debounce from 'lodash/debounce';
import utilsMixin from '@shared/mixins/utils';
import APIStore from '@app/services/API/Store';
import ProgressCircle from '@app/components/ProgressCircle.vue';
import CustomerAddForm from '@app/components/CustomerAddForm.vue';
import CustomerImportForm from '@app/components/CustomerImportForm.vue';
import CustomerTrainingCard from '@app/components/CustomerTrainingCard.vue';
import CustomerListItemDropdown from '@app/components/CustomerListItemDropdown.vue';

const FILTERS_MAP = {
  q: 'q',
  sort_by: 'sort',
  order_by: 'order',
  customer_type: 'type',
  page: 'page',
  training_uuid: 'uuid',
};

export default {
  name: 'CustomerList',
  mixins: [utilsMixin],
  components: {
    ProgressCircle,
    CustomerAddForm,
    CustomerImportForm,
    CustomerListItemDropdown,
  },
  props: {
    training: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      customerFormIsOpen: false,
      importFormIsOpen: false,
      params: this.getQueryParams(),
      customers: { data: [], links: {}, meta: {} },
    };
  },
  computed: {
    store() {
      return this.$store.getters['auth/store'];
    },
  },
  watch: {
    '$route.query': 'onQueryChange',
  },
  methods: {
    onQueryChange() {
      this.params = this.getQueryParams();
      this.fetchResults();
    },
    getQueryParams() {
      const { query } = this.$route;
      return {
        q: query[FILTERS_MAP.q] || '',
        sort_by: query[FILTERS_MAP.sort_by] || 'created_at',
        order_by: query[FILTERS_MAP.order_by] || 'desc',
        customer_type: query[FILTERS_MAP.customer_type] || 'STUDENT',
        page: query[FILTERS_MAP.page] || 1,
        training_uuid: query[FILTERS_MAP.training_uuid] || (this.training ? this.training.uuid : ''),
      };
    },
    search(clearSortColumn = false) {
      if (clearSortColumn) {
        this.$refs.table.currentSortColumn = {};
      }

      const params = { ...this.params };
      params.q === '' && delete params.q;
      params.sort_by === '' && delete params.sort_by;
      params.training_uuid === '' && delete params.training_uuid;

      const { href } = this.$router.resolve({
        query: {
          [FILTERS_MAP.q]: params.q,
          [FILTERS_MAP.sort_by]: params.sort_by,
          [FILTERS_MAP.order_by]: params.order_by,
          [FILTERS_MAP.customer_type]: params.customer_type,
          [FILTERS_MAP.page]: params.page,
          [FILTERS_MAP.training_uuid]: params.training_uuid,
        },
      });

      if (href === this.$route.fullPath) {
        this.onQueryChange();
      } else {
        this.$router.replace(href);
      }
    },
    fetchResults() {
      if (this.fetchPromise) {
        this.fetchPromise.cancel();
        this.fetchPromise = null;
      }

      const params = { ...this.params };
      params.q === '' && delete params.q;
      params.sort_by === '' && delete params.sort_by;
      params.training_uuid === '' && delete params.training_uuid;

      const loader = this.$buefy.loading.open({ container: this.$refs.box });
      this.fetchPromise = APIStore.getCustomers(this.store.uuid, params)
        .then((data) => (this.customers = data))
        .finally(() => loader.close());

      return this.fetchPromise;
    },
    onSearch: debounce(function(value) {
      this.params.q = value.trim();
      this.search();
    }, 300),
    onSort(sort, order) {
      this.params.sort_by = sort;
      this.params.order_by = order;
      this.search();
    },
    onPageChange(page) {
      this.params.page = page;
      window.scroll(0, 0);
      this.search();
    },
    toggleOrder() {
      this.params.order_by = this.params.order_by !== 'desc' ? 'desc' : 'asc';
      this.search(true);
    },
    addCustomer() {
      this.customerFormIsOpen = true;
    },
    importCustomers() {
      this.importFormIsOpen = true;
    },
    onCustomerAdd() {
      this.customerFormIsOpen = false;
      this.search();
    },
    onCustomersImport() {
      this.importFormIsOpen = false;
      this.$buefy.dialog.alert(`
        Votre import est en cours de traitement.
        <br>
        Les apprenants seront ajoutés dans les minutes qui suivent.
      `);
    },
    onCustomerUpdate(customer) {
      this.customers.data = this.customers.data.map((one) => {
        if (one.uuid === customer.uuid) {
          return customer;
        }

        return one;
      });
    },
    onCustomerDelete(customer) {
      this.customers.data = this.customers.data.filter((one) => one.uuid !== customer.uuid);
    },
    showProgress(training, customer) {
      const loader = this.$buefy.loading.open();
      APIStore.getCustomerTraining(this.store.uuid, customer.uuid, training.uuid)
        .then(({ data }) => this.$buefy.modal.open({
          component: CustomerTrainingCard,
          parent: this,
          width: '768px',
          props: { customer, customerTraining: data },
        }))
        .finally(() => loader.close());
    },
    getProgressPercentage(item) {
      return Math.round((item.completed_module_count / item.total_module_count) * 100);
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<template>
  <div class="clcomp">
    <div class="columns">
      <div class="column">
        <b-field label="Filtrer par">
          <b-input
            placeholder="Filtrez par email, prénom, nom ou tag"
            :value="params.q"
            expanded
            @input="onSearch"
          />
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Type">
          <b-select v-model="params.customer_type" @input="search()">
            <option value="ABANDONED_CART" :disabled="$store.getters['auth/hasPercentPlan']">
              <template v-if="$store.getters['auth/hasPercentPlan']">
                (Forfait PRO)
              </template>
              Paniers abandonnés
            </option>
            <!-- <option value="PRE_LAUNCH">Pré-inscriptions</option> -->
            <option value="STUDENT">Inscrits</option>
            <option value="BLOCKED">Bloqués</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Trier par">
          <b-select v-model="params.sort_by" @input="search(true)">
            <option value="email">Email</option>
            <option value="firstname">Prénom</option>
            <option value="lastname">Nom</option>
            <option value="created_at">Date d'inscription</option>
            <option value="last_login_at">Dernière activité</option>
            <option value="revenus">Revenus</option>
            <option value="trainings_count">Nombre de formations</option>
          </b-select>
          <p class="control">
            <b-tooltip :label="params.order_by == 'asc' ? 'croissant' : 'décroissant'">
              <b-button
                :icon-left="params.order_by == 'asc' ? 'arrow-up' : 'arrow-down'"
                @click="toggleOrder"
              />
            </b-tooltip>
          </p>
        </b-field>
      </div>
    </div>

    <div class="mb-3 level is-flex-wrap-wrap">
      <div class="level-left">
        <p v-if="customers.meta.total != null" class="py-3 mr-3">
          Résultat :
          <strong>
            {{ customers.meta.total }} apprenant{{ customers.meta.total > 1 ? 's' : '' }}
          </strong>
        </p>
      </div>

      <div class="level-right is-hidden-mobile">
        <b-button type="is-primary" icon-left="user-plus" @click="addCustomer">
          Inviter un apprenant
        </b-button>
        <b-button class="ml-3" type="is-primary" icon-left="upload" @click="importCustomers">
          Import CSV
        </b-button>
      </div>
    </div>

    <b-modal :width="460" :active.sync="customerFormIsOpen">
      <CustomerAddForm :training="training" @add="onCustomerAdd" />
    </b-modal>

    <b-modal :width="460" :active.sync="importFormIsOpen">
      <div class="box">
        <CustomerImportForm :training="training" @import="onCustomersImport" />
      </div>
    </b-modal>

    <div ref="box" class="box is-relative mxw-full">
      <b-table
        ref="table"
        class="is-middle"
        :data="customers.data"
        :total="customers.meta.total"
        :per-page="customers.meta.per_page"
        detailed
        backend-sorting
        paginated
        backend-pagination
        @sort="onSort"
        @page-change="onPageChange">
        <b-table-column
          cell-class="clcomp_firstname py-5"
          v-slot="{ row }"
          field="firstname"
          label="Apprenant"
          sortable
        >
          <router-link
            class="clcomp_customer-link is-block mb-3"
            :to="{name: 'customer', params: {uuid: row.uuid}}"
          >
            {{ row.firstname }} {{ row.lastname }} <br>
            <strong>{{ row.email }}</strong>
          </router-link>
        </b-table-column>
        <b-table-column v-if="training" v-slot="{ row }" label="Avancée" numeric>
          <template v-for="one in row.customer_trainings">
            <a
              v-if="training.uuid == one.uuid"
              :key="one.uuid"
              href="#"
              @click.prevent="showProgress(training, row)">
              <b-tooltip :label="$t('customer.see_progression')">
                <span class="is-flex is-align-items-center">
                  <ProgressCircle
                    class="mr-2"
                    :max="one.total_module_count"
                    :value="one.completed_module_count"
                    :size="20"
                    :borderSize="2"
                  />
                  {{ one.completed_module_count }} / {{ one.total_module_count }}
                </span>
              </b-tooltip>
            </a>
          </template>
        </b-table-column>
        <b-table-column cell-class="is-size-8" v-slot="{ row }" label="Activité" numeric>
          <div>
            <p>
              <b-tooltip>
                <template #content>
                {{ row.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
                <strong>Inscrit</strong>
                {{ row.created_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
            <p v-if="row.last_login_at" class="mt-1">
              <b-tooltip :label="$moment.utc(row.last_login_at).local().format('DD/MM/YY [à] HH:mm')">
                <strong>Connecté</strong> {{ row.last_login_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
            <p v-else class="mt-1 has-text-weight-bold">
              Jamais connecté
            </p>
          </div>
        </b-table-column>
        <b-table-column cell-class="is-size-8" field="revenus" label="Revenus" numeric sortable>
          <template #default="{ row }">
            <div>
              <template v-if="params.customer_type != 'ABANDONED_CART'">
                {{ (row.revenus || 0) | formatPrice }}
                <br>
                <em>
                    {{ row.customer_trainings_count }}
                    formation{{ row.customer_trainings_count > 1 ? 's' : '' }}
                </em>
              </template>
              <template v-else>
                N/A
              </template>
            </div>
          </template>
        </b-table-column>
        <b-table-column cell-class="py-5" v-slot="{ row }" width="40">
          <b-tooltip label="Voir la fiche détaillée" position="is-left">
            <b-button
              tag="router-link"
              :to="{name: 'customer', params: {uuid: row.uuid}}"
              type="is-text"
              icon-left="eye"
            />
          </b-tooltip>
          <CustomerListItemDropdown
            :customer="row"
            :training="training"
            @show-progression="showProgress(training, row)"
            @update-status="onCustomerUpdate"
            @delete="onCustomerDelete"
          />
        </b-table-column>

         <template #detail="{ row }">
           <p v-if="!row.customer_trainings.length" class="notification is-info">
             Aucune formation
           </p>
           <template v-for="one in row.customer_trainings">
             <article v-if="!training || training.uuid == one.uuid" :key="one.uuid">
              <div class="columns">
                <div class="column">
                  <router-link
                    class="cursor-pointer"
                    :class="{'pevents-none': training}"
                    tag="strong"
                    :to="{name: 'training', params:{uuid: one.uuid}}">
                    {{ one.name }}
                  </router-link>
                </div>
                <div class="column is-narrow tags">
                  <a class="tag" href="#" @click.prevent="showProgress(one, row)">
                    <b-tooltip :label="$t('customer.see_progression')">
                      {{ one.completed_module_count }} / {{ one.total_module_count }}
                      {{ one.total_module_count > 1 ? 'leçons' : 'leçon' }}
                      ({{ getProgressPercentage(one) }}%)
                    </b-tooltip>
                  </a>
                  <span v-if="one.from_bundle && !one.paid_price" class="tag">
                    Obtenue via le pack {{ one.from_bundle.name }}
                  </span>
                  <span v-else class="tag">
                    Achetée {{ one.paid_price | formatPrice }}
                  </span>
                </div>
              </div>
              <div class="columns is-size-8">
                <p class="column">
                  <template v-if="!one.started_at">
                    Pas encore commencée
                  </template>
                  <template v-else>
                    Commencée
                    <b-tooltip :label="$moment.utc(one.started_at).local().format('DD/MM/YY [à] HH:mm')">
                      {{ one.started_at | momentFromUTC | moment('from') }}
                    </b-tooltip>
                  </template>
                </p>
                <p v-if="one.last_activity_at" class="column is-narrow">
                  <strong>Dernière leçon vue : </strong>
                  <b-tooltip :label="$moment.utc(one.last_activity_at).local().format('DD/MM/YY [à] HH:mm')">
                    {{ one.last_activity_at | momentFromUTC | moment('from') }}
                  </b-tooltip>
                </p>
              </div>
              <b-progress
                  class="w-full"
                  type="is-success"
                  size="is-small"
                  :value="one.completed_module_count"
                  :max="one.total_module_count"
                />
            </article>

            <hr v-if="!training || training.uuid == one.uuid" :key="`hr${one.uuid}`">
           </template>
         </template>

         <template #empty>
            <p class="p-5 has-text-centered">
             Il n'y pas de résultat pour cette recherche
            </p>
         </template>
      </b-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clcomp {
  ::v-deep &_firstname {
    max-width: 200px;
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  &_customer-link {
    color: $black;

    &:hover {
      color: $theme_color_primary_hover;
    }
  }

  @include mobile {
    ::v-deep &_firstname {
      max-width: none;
    }
  }
}
</style>
